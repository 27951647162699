import classNames from "classnames"
import { Field, Form, Formik } from "formik"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import * as Yup from "yup"
import {
	deletedAvatar,
	getUser,
	localesSelector,
	personSelector,
	sendAvatar,
	sendUser,
} from "../../store/reducers/user"
import MobileTitle from "../Mobile/MobileTitle"
import Button from "../forms/button/Button"
import InputControl, { PasswordControl } from "../forms/input-control"
import { SelectControl } from "../forms/select"
import styles from "./Profile.module.sass"
import ProfileAvatar from "./profile-avatar/ProfileAvatar"

import { useEffect } from "react"
import {
	countiresSelector,
	timezonesSelector,
} from "../../store/reducers/domainTheme"
import Typography from "../UIKit/base/Typography"
import {
	DepartmentBorderIcon,
	LocationIcon,
	MainInfoIcon,
	PasswordIcon,
} from "../icons"
import Radio from "../forms/radio/Radio"
import DatePickerControl from "../forms/dataPickerControl/DatePickerControl"
import { format } from "date-fns"
import { globalLoadSelector, setShowLoader } from "../../store/reducers/loader"
import PreloaderUser from "../Preloader/Preloaders/PreloaderUser"
import FormikValueObserverControl from "../forms/FormikValueObserverControl/FormikValueObserverControl"

export default function Profile() {
	const user = useSelector(personSelector)
	const dispatch = useDispatch()
	const timezones = useSelector(timezonesSelector)
	const locales = useSelector(localesSelector)
	const countries = useSelector(countiresSelector)
	const { t } = useTranslation()

	const loading = useSelector(globalLoadSelector).loading

	useEffect(() => {
		dispatch(setShowLoader(false))

		return () => {
			dispatch(setShowLoader(true))
		}
	}, [dispatch])

	useEffect(() => {
		dispatch(getUser())
	}, [dispatch])

	if (loading) {
		return <PreloaderUser />
	}

	if (!user) {
		return null
	}

	const initialValues = {
		email: user.email || "",
		name: user.name || "",
		phone: user.phone || "",
		location: user.location || "",
		timezone: user.timezone || "",
		language: user.language || "",
		password: user.password || "",
		bio: user.bio || "",
		current_password: "",
		new_password: "",
		gender: user.gender || "",
		birthday: user.birthday || "",
	}

	const validationSchema = Yup.object().shape(
		{
			email: Yup.string().required(t("email_required")),
			name: Yup.string()
				.matches(
					/^[a-zA-Zа-яА-Яа-щА-ЩЬьЮюЯяЇїІіЄєҐґ'-\s]+$/,
					t("field_only_letters")
				)
				.required(t("name_required"))
				.max(255),
			phone: Yup.string()
				.matches(/^([0-9\s\-\+\(\)]*)$/, t("phone_invalid"))
				.min(10)
				.max(255),
			//	.required(t("phone_required", t("phone_invalid"))),
			location: Yup.string(),
			timezone: Yup.string().required(),
			language: Yup.string().required(),
			password: Yup.string(),
			bio: Yup.string(),
			current_password: Yup.string().when("new_password", {
				is: (val) => val?.length > 0,
				then: Yup.string()
					.matches(
						/^(?=.*\d)(?=.*[a-z])(?=.*[a-zA-Z]).{8,}$/,
						t("password_helper")
					)
					.required(t("password_required")),
				otherwise: Yup.string().matches(
					/^(?=.*\d)(?=.*[a-z])(?=.*[a-zA-Z]).{8,}$/,
					t("password_helper")
				),
			}),
			new_password: Yup.string().when("current_password", {
				is: (val) => val?.length,
				then: Yup.string()
					.matches(
						/^(?=.*\d)(?=.*[a-z])(?=.*[a-zA-Z]).{8,}$/,
						t("password_helper")
					)
					.required(t("password_required")),
				otherwise: Yup.string().matches(
					/^(?=.*\d)(?=.*[a-z])(?=.*[a-zA-Z]).{8,}$/,
					t("password_helper")
				),
			}),
		},
		["current_password", "new_password"]
	)

	function handleSubmit(values, { resetForm }) {
		values.birthday = values.birthday
			? format(new Date(values.birthday), "yyyy-MM-dd")
			: ""
		dispatch(sendUser({ values, cb: resetForm }))
	}
	function handleAvatar(file) {
		if (file) {
			dispatch(sendAvatar(file))
		}
	}

	function handleDeleteAvatar() {
		dispatch(deletedAvatar())
	}

	return (
		<>
			<MobileTitle title={t("profile")} />
			<div className={styles.wrapper}>
				<Formik
					enableReinitialize
					initialValues={initialValues}
					validationSchema={validationSchema}
					autocomlete="off"
					onSubmit={handleSubmit}
				>
					{() => (
						<Form>
							<FormikValueObserverControl />
							<div className={styles.header}>
								<Typography
									component="h2"
									className={styles.header__title}
									fontVariant="bold"
								>
									{t("account_settings")}
								</Typography>
								<Button type="submit">save</Button>
							</div>
							<div className={styles.container}>
								<div className={styles.section}>
									<Typography className={styles.title}>
										<MainInfoIcon />
										{t("general_information")}
									</Typography>
									<ProfileAvatar
										className={styles.avatar}
										avatar={user?.avatar}
										name={user?.name}
										handleAvatar={handleAvatar}
										handleDelete={handleDeleteAvatar}
									/>

									<Field
										component={InputControl}
										name="email"
										className={classNames(styles.input)}
										label={t("email")}
										placeholder={t("enter_email_profile")}
										message
										required
									/>
									<Field
										component={InputControl}
										name="name"
										className={classNames(styles.input)}
										label={t("name")}
										placeholder={t("enter_name")}
										message
										required
									/>
									<Field
										component={InputControl}
										name="phone"
										className={classNames(styles.input)}
										label={t("phone")}
										placeholder="+380 099 999 99 99"
										message
									/>
									<DatePickerControl
										label={t("birth_date")}
										name="birthday"
										readOnly={false}
										beforeAllowed={true}
										placeholder={t("choose_date")}
										hasRemoveIcon={true}
										afterAllowed={false}
									/>
									<div className={styles.radio__field}>
										<Typography
											className={styles.radio__title}
											fontVariant="medium"
										>
											{t("sex")}
										</Typography>
										<div className={styles.radio__wrapper}>
											<Radio
												className={styles.radio}
												name="gender"
												value="male"
											>
												<Typography
													className={styles.radio__label}
													fontVariant="medium"
												>
													{t("male")}
												</Typography>
											</Radio>
											<Radio
												className={styles.radio}
												name="gender"
												value="female"
											>
												<Typography
													className={styles.radio__label}
													fontVariant="medium"
												>
													{t("female")}
												</Typography>
											</Radio>
										</div>
									</div>

									<Field
										component={InputControl}
										name="bio"
										className={classNames(
											classNames(styles.input, styles.textarea)
										)}
										label={t("about_me")}
										placeholder={t("enter_description")}
										variant="textarea"
										message
									/>
								</div>
								<div className={styles.col}>
									<div className={styles.section}>
										<Typography className={styles.title}>
											<LocationIcon />
											{t("location")}
										</Typography>
										{countries && (
											<Field
												component={SelectControl}
												placeholder={"country"}
												labelClassName={classNames(styles.label, styles.select)}
												label={"country"}
												name="location"
												options={countries}
												className={styles.input}
												search
											/>
										)}
										{timezones && (
											<Field
												component={SelectControl}
												placeholder={"time_zone"}
												labelClassName={classNames(styles.label, styles.select)}
												label={"time_zone"}
												name="timezone"
												options={timezones}
												className={styles.input}
												search
											/>
										)}
										<Field
											component={SelectControl}
											placeholder={"language"}
											labelClassName={styles.label}
											label={"interface_language"}
											options={locales}
											name="language"
											className={classNames(styles.input)}
											wrapperClassName={styles.select}
										/>
									</div>
								</div>
								<div className={styles.col}>
									<div className={styles.section}>
										<Typography className={styles.title}>
											<PasswordIcon />
											{t("password")}
										</Typography>
										<Field
											component={PasswordControl}
											placeholder={t("enter_password")}
											labelClassName={styles.label}
											label={t("old_password")}
											name="current_password"
											message
											autocomlete="false"
										/>
										<Field
											component={PasswordControl}
											placeholder={t("enter_password")}
											labelClassName={styles.label}
											label={t("new_password")}
											name="new_password"
											message
										/>
									</div>
									<div className={styles.section}>
										<Typography className={styles.title}>
											<DepartmentBorderIcon />
											{t("department")}
										</Typography>
										<div
											className={classNames(
												styles.card__main,
												styles.card__departmentWrapper
											)}
										>
											<div className={styles.card__department}>
												<Typography
													className={classNames(
														styles.card__label,
														styles.card__departmentName
													)}
												>
													{user?.department?.department_name || "-"}
												</Typography>
												<Typography
													className={classNames(
														styles.card__label,
														styles.card__departmentParent
													)}
													fontVariant="light"
												>
													{user?.department?.department_parent_name || "-"}
												</Typography>
											</div>
											<div className={styles.card__info}>
												<Typography className={styles.card__label}>
													{t("position")}
												</Typography>
												<Typography className={styles.card__value}>
													{user?.department?.department_position_name || "-"}
												</Typography>
											</div>
										</div>
									</div>
								</div>
							</div>
						</Form>
					)}
				</Formik>
			</div>
		</>
	)
}
