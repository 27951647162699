import React, { useMemo } from "react"
import styles from "../Lesson/lesson.module.scss"

import { useSelector } from "react-redux"
import {
	answersSelector,
	attachmentsSelector,
	postSelector,
} from "../../store/reducers/lesson"
import Attachments from "../attachments/Attachments"
import Answer from "../Answer/Answer"
import Reviews from "./HomeWorkReviews"
import Typography from "../UIKit/base/Typography"
import useScrollTop from "../hooks/useScrollTop"
import EditorView from "../UIKit/editor/view/EditorView"
import ScoreProgress from "../ScoreProgress"
import classNames from "classnames"
import { useTranslation } from "react-i18next"

const Homework = () => {
	const post = useSelector(postSelector)
	const attachments = useSelector(attachmentsSelector)

	const answers = useSelector(answersSelector)

	const { t } = useTranslation()

	const score = useMemo(() => {
		return answers?.[0]
	}, [answers])

  const postHasDesc = useMemo(() => {
		return !!post?.description?.replace(/<\/?[^>]+(>|$)/g, "")
	}, [post?.description])

	useScrollTop(post)


	return (
		<>
			<div className={styles.card_big}>
				{(postHasDesc || !!attachments.length) && (
					<div className={classNames(styles.card, styles.homework__desc)}>
						<div className={styles.container}>
							{postHasDesc && (
								<EditorView
									content={post?.description}
									fontVariant="medium"
									className={styles.description}
								/>
							)}
							{!!attachments.length && (
								<div className={styles.attachments__container}>
									<Typography className={styles.card__title}>
										{t("additional_materials")}
									</Typography>
								<div
									className={classNames(
										styles.attachments__wrapper,
										styles.attachments__wrapper_task,
                    {[styles.wrapFiles_top]: postHasDesc}
									)}
								>
									<Attachments
										attachments={attachments}
										className={styles.attachments}
										titleClassName={styles.attachments__title}
										course
									/>
								</div></div>
							)}
						</div>
					</div>
				)}
				{(post?.status === "completed" || post?.status === "failed") && (
					<div className={classNames(styles.card, styles.homework__done)}>
						<Typography
							fontVariant="medium"
							className={styles.homework__done_title}
						>
							{t("homework_completed")}
						</Typography>
						<Typography
							fontVariant="medium"
							className={styles.homework__done_text}
						>
							{post?.status === "completed"
								? t("homework_completed_desc")
								: t("homework_failed")}
						</Typography>

						<ScoreProgress
							score={score?.score}
							total={score?.passing_total}
							passing={score?.passing_score}
						/>
					</div>
				)}
			</div>
			<Answer />
			<Reviews />
		</>
	)
}

export default Homework
